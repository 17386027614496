// import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; // Import BrowserRouter, Route, and Switch
import QuestionPage from './pages/Questions';
import RiskProfilePage from './pages/RiskProfilePage'; // Import the RiskProfilePage component

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" exact element={<QuestionPage />} /> {/* Route for the question page */}
          <Route path="/risk-profile" element={<RiskProfilePage />} /> {/* Route for the risk profile page */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
