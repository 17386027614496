import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function QuestionPage() {
  const Redirect = useNavigate();
  const [questions, setQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedOption, setSelectedOption] = useState(null);
  const [questionsCompleted, setQuestionsCompleted] = useState(false);

  useEffect(() => {
    // Fetch question data from your API when the component mounts
    fetchQuestion();
  }, []);
  const fetchQuestion = async () => {
    try {
      // eslint-disable-next-line no-undef
      const base_url = "api.kumbhtech.com";
      // Make API request to fetch question data
      const response = await fetch(
        `https://${base_url}/api/robo-advisory-questions`
      );
      const data = await response.json();
      setQuestions(data.data);
    } catch (error) {
      console.error("Error fetching question:", error);
    }
  };

  const handleSubmit = async () => {
    try {
      // Make API request to submit answer
      const base_url = "api.kumbhtech.com";
      const response = await fetch(
        `https://${base_url}/api/client-rb-question-responses`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            client_id: 5,
            question_id: questions[currentQuestionIndex].id,
            option_id: selectedOption,
          }),
        }
      );

      if (response.ok) {
        // If response is successful, move to the next question
        if (currentQuestionIndex < questions.length - 1) {
          setCurrentQuestionIndex(currentQuestionIndex + 1);
          setSelectedOption(null); // Reset selected option for the next question
        } else {
          setQuestionsCompleted(true); // Set questionsCompleted to true when all questions are answered
        }
      } else {
        // Handle unsuccessful response
        console.error("Error submitting answer:", response.statusText);
        toast.error("Sorry, something went wrong.");
      }
    } catch (error) {
      console.error("Error submitting answer:", error);
      toast.error("Sorry, something went wrong.");
    }
  };

  const handleOptionChange = (optionId) => {
    setSelectedOption(optionId);
  };

  const currentQuestion = questions[currentQuestionIndex];

  if (!currentQuestion) {
    return <div>Loading...</div>;
  }
  if (questionsCompleted) {
    // Redirect to the risk profile page if questions are completed
    return Redirect("/risk-profile");
  }
  const handleBackToPreviousQuestion = () => {
    setCurrentQuestionIndex((prevIndex) => prevIndex - 1); // Navigate to the previous question
    setSelectedOption(null); // Reset selected option
    setQuestionsCompleted(false); // Reset questionsCompleted flag
  };
  return (
    <div>
      <div className="navbar fixed-top">
        <div className="container-fluid">
          <div className="fx_fs ai_center">
            <div>
              <i className="bi bi-arrow-left wh"></i>
            </div>
            <div>
              <h5 className="wh heading">Risk Profile</h5>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="whitebx page_wraper">
          <div className="row justify-content-center gx-5">
            <div className="col-lg-12 col-md-12 col-sm-12 ">
              <div className="page-name">
                <div className="bullet-point">
                  <div className="span1"></div>
                  <div className="span2"></div>
                </div>

                <h4 className="question al_center">
                  Question {currentQuestionIndex + 1}:{" "}
                  {currentQuestion.question}
                </h4>
              </div>
            </div>

            {currentQuestion.options.map((option) => (
              <div className="col-lg-2 col-md-4 col-sm-4 col-6" key={option.id}>
                <div className="ansbx">
                  <label htmlFor={`option_${option.id}`} className="clickable">
                    <div className="flip-card">
                      <div className="flip-card-inner">
                        <div className="flip-card-front">
                          <img
                            src={`../images/riskquestion/rq_${option.id}a.svg`}
                            alt="Avatar"
                            className="wi100"
                          />
                        </div>
                        <div className="flip-card-back">
                          <img
                            src={`../images/riskquestion/rq_${option.id}b.svg`}
                            alt="Avatar"
                            className="wi100"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="al_center">
                      <input
                        className="form-check-input al_center"
                        type="radio"
                        name="flexRadioDefault"
                        id={`option_${option.id}`}
                        checked={selectedOption === option.id}
                        onChange={() => handleOptionChange(option.id)}
                      />
                      <h6 className="option">{option.text}</h6>
                    </div>
                  </label>
                </div>
              </div>
            ))}
          </div>
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover={false}
            theme="light"
          />
        </div>

        <div className="al_center_mob my-3">
          {currentQuestionIndex > 0 && (
            <button
              className="btn btn-sm btn-dark back_btn"
              onClick={handleBackToPreviousQuestion}
            >
              Back
            </button>
          )}
          {/* Change the link to navigate to the next question */}
          <button
            className="btn btn-sm btn-dark back_btn"
            onClick={handleSubmit}
          >
            Next &nbsp; <i className="bi bi-arrow-right"></i>
          </button>
        </div>
      </div>
    </div>
  );
}

export default QuestionPage;
