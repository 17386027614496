// RiskProfile.js

import React,{useState,useEffect} from "react";
import GaugeChart from "react-gauge-chart";

function RiskProfilePage() {
  
  const [riskLevel, setRiskLevel] = useState([]); // Initial risk level
  const [minScore,setMinscore] =useState(0);

  useEffect(() => {
    // Fetch risk level data from the backend using an API
    fetchRiskLevel();
  }, []);

  const fetchRiskLevel = async () => {
    try{
      const base_url = "api.kumbhtech.com";
      const id = "3";
      const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6IlNCQzAwMSIsImlkIjo5LCJpYXQiOjE3MTMzNDg1MzgsImV4cCI6MTcxMzk1MzMzOH0.PIZh9urMzzON-EikQJBK0rok5ZlGNzsYqE8pE27qYNI"; // Replace "your_bearer_token_here" with your actual token
      
      // Construct the authorization header
      const headers = new Headers();
      headers.append("Authorization", `Bearer ${token}`);
      headers.append("Content-Type", "application/json");

      // Make API request to fetch risk level data with authorization header
      const response = await fetch(`https://${base_url}/api/clients/${id}`, {
        method: "GET",
        headers: headers,
      });

      if (response.ok) {
        const data = await response.json();
        console.log(data.riskProfile);
        setRiskLevel(data); // Set the risk level obtained from the backend
      } else {
        console.error("Error fetching risk level:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching risk level:", error);
    }
  };


  useEffect(()=>{
    if(riskLevel && riskLevel.data){
      setMinscore(riskLevel.data.riskProfile.minScore)
    }
  })
// console.log(riskLevel.data.riskProfile.minScore);

  
  return (
    <div>
      <body>
        <div class="navbar fixed-top">
          <div class="container-fluid">
            <div class="fx_fs ai_center">
              <div>
                <i class="bi bi-arrow-left wh"></i>
              </div>
              <div>
                <h5 class="wh heading">Risk Profile</h5>
              </div>
            </div>
          </div>
        </div>

        <div class="container-fluid">
          <div class="whitebx page_wraper">
            <div class="row justify-content-center">
              <div class="col-lg-12 col-md-12 col-sm-12 ">
                <div class="page-name">
                  <div class="bullet-point">
                    <div class="span1"></div>
                    <div class="span2"></div>
                  </div>

                  <h4 class="question al_center">
                  Your Risk Profile is: {riskLevel?.data?.riskProfile?.profile ? riskLevel.data.riskProfile.profile : '-'}
                  </h4>
                </div>
              </div>

              <div class="col-lg-4 col-md-4 col-sm-4">
                <div class="ansbx my-2">
                  <div>
                    <GaugeChart
                      id="risk-meter"
                      nrOfLevels={5}
                      colors={["#8B0000", "#FF0000","#FFFF00","#00FF00","#008000"]}  
                      percent={minScore/100} // Set the risk level as the percent value
                      arcPadding={0.08}
                      arcWidth={0.30}
                      textColor="#000"
                      needleColor="#D3D3D3"
                      needleBaseColor="#D3D3D3"
                      fontSize="20px"
                      animate={true}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-lg-8 col-md-8 col-sm-12">
                <h6 class="resk_meter_subheading al_center">
                  Based on your answers your profile is determined. We have
                  analysed your answers in bacliground where the following
                  aspects were checked by our intelligent system. 1. Demographic
                  Details like age and income. 2. Investment Knowledge 3.
                  Ability to take risk 4 Comfort with nsk 5. Willingness to take
                  mak. You can always edit your answers and update your misk
                  profile
                </h6>
              </div>
            </div>
          </div>

          <div class="al_center_mob my-3">
            <a href="/">
              {" "}
              <button class="btn btn-sm btn-dark proceed_btn">
                Proceed To Plan My Goals &nbsp;{" "}
              </button>
            </a>
          </div>
        </div>
      </body>
    </div>
  );
}

export default RiskProfilePage;
